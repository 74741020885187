import { HttpClient } from '@angular/common/http';
import { CommonService } from '@app/core/service/common.service';
import { environment } from '@env/environment';
import { Observable, catchError, map, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { GetTemplateParams } from '../templates/api.templates.model';
import {
  AddAutomationBody,
  GetAutomationById,
  GetAutomationResponse,
  GetSenderEmail,
  TriggerRuleModel
} from './api.automation.model';

export class AutomationApi {
  private apiUrl = environment.API_URL;

  AUTOMATION = '/automations';
  EMAIL_MARKETING = '/email-marketing';

  constructor(public readonly http: HttpClient, public config: ApiClientConfig, private commonService: CommonService) {}

  getAutomations(params: GetTemplateParams): Observable<GetAutomationResponse> {
    return this.http
      .get<GetAutomationResponse>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.AUTOMATION}`, {
        params: { ...params }
      })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getAutomationById(id: string): Observable<GetAutomationById> {
    return this.http.get<GetAutomationById>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.AUTOMATION}/${id}`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getTriggerRules(): Observable<TriggerRuleModel[]> {
    return this.http
      .get<TriggerRuleModel[]>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.AUTOMATION}/trigger-rules`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getTriggerRuleDetail(triggerRuleId: string): Observable<TriggerRuleModel> {
    return this.http
      .get<TriggerRuleModel>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.AUTOMATION}/trigger-rules/${triggerRuleId}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  addAutomation(body: AddAutomationBody): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.AUTOMATION}`, body).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  editAutomation(body: AddAutomationBody, id: string): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.AUTOMATION}/${id}`, body).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getConditionRules(): Observable<TriggerRuleModel[]> {
    return this.http
      .get<TriggerRuleModel[]>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.AUTOMATION}/condition-rules`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  toggleAutomationStatus(id: string): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.AUTOMATION}/${id}/toggle`, {}).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  deleteAutomation(id: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.AUTOMATION}/${id}`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getSenderEmail(): Observable<GetSenderEmail> {
    return this.http
      .get<GetSenderEmail>(
        `${this.apiUrl}${this.EMAIL_MARKETING}/campaigns/${this.commonService.moduleType.name}/sender-emails`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
