import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appScaleContent]'
})
export class ScaleContentDirective implements AfterViewInit {
  private containerElement: HTMLElement;
  private contentElement: HTMLElement;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.containerElement = this.el.nativeElement;
    this.contentElement = this.containerElement.querySelector('.content') as HTMLElement;
    this.scaleAndCenterContent();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.scaleAndCenterContent();
  }

  private scaleAndCenterContent(): void {
    if (this.containerElement && this.contentElement) {
      const containerWidth = this.containerElement.offsetWidth;
      const containerHeight = this.containerElement.offsetHeight;
      const contentWidth = this.contentElement.offsetWidth;
      const contentHeight = this.contentElement.offsetHeight;

      // Calculate scale ratios for width and height
      const widthRatio = containerWidth / contentWidth;
      const heightRatio = containerHeight / contentHeight;

      // Use the smaller ratio to scale content uniformly
      const scale = Math.min(widthRatio, heightRatio);

      // Apply the scale and center the content
      this.contentElement.style.transform = `scale(${scale})`;
      this.contentElement.style.transformOrigin = 'top left';
      this.contentElement.style.position = 'absolute';
      this.contentElement.style.top = '50%';
      this.contentElement.style.left = '50%';
      this.contentElement.style.transform += ' translate(-50%, -50%)';
    }
  }
}
