import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TemplatesService } from '@app/core/service/templates.service';
import { ToastMessageService } from '@app/core/service/toast-message.service';
import { TranslationService } from '@app/core/service/translation.service';
import { TemplateModel } from '@app/lib/api/templates/api.templates.model';
import { TemplatesActions, TemplatesApiActions } from '@app/modules/main/templates/store/email-template.actions';
import {
  selectFooterTemplates,
  selectHeaderTemplates,
  selectLoadingTemplates,
  selectMarketingTemplates,
  selectSystemTemplates
} from '@app/modules/main/templates/store/email-template.selectors';
import {
  CHECKBOX_TYPE,
  ITEM_NAMES,
  PAGE_NUM_DEFAULT,
  PAGE_SIZE_DEFAULT,
  TEMPLATE_TYPES,
  TOAST_MESSAGE_LIFETIME_DURATIONS,
  TOAST_MESSAGE_SEVERITY_LEVELS,
  TOTAL_ELEMENT_DEFAULT
} from '@app/shared/constant';
import { Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { Pagination } from '../paginator/paginator.component';
import { SmartTableComponent } from '../smart-table/smart-table.component';

@Component({
  selector: 'em-template-table',
  templateUrl: './template-table.component.html',
  styleUrls: ['./template-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TemplateTableComponent implements OnInit, OnChanges {
  @ViewChild(SmartTableComponent) smartTableComponent!: SmartTableComponent;
  @Input() showTableCheckbox = false;
  @Input() showOnlyGridLayout = false;
  @Input() isShowConfigHeaderFooter = false;
  @Input() module = 'ALL';
  @Input() templateModule: string;
  @Input() emailType: any;
  @Input() isSelectTemplateTable = false;
  @Input() configTemplate = false;
  @Output() selectTemplateEvent = new EventEmitter();
  @Output() onSelectHeaderTemplate = new EventEmitter();
  @Output() onSelectFooterTemplate = new EventEmitter();

  isLoadingTemplate$ = this.store.select(selectLoadingTemplates);

  tableData: any;
  headerList = ['TEMPLATE_NAME', 'CREATED_DATE', 'UPDATED_DATE'];
  tableName = ITEM_NAMES.templates;
  audienceTableOptions: { label: string }[];
  checkBoxType = CHECKBOX_TYPE;
  visibleAddTemplateDialog = false;
  pageNum = PAGE_NUM_DEFAULT;
  pageSize = PAGE_SIZE_DEFAULT;
  searchText = new FormControl('');
  searchTextParam = '';
  totalElements = TOTAL_ELEMENT_DEFAULT;
  templateList: any = [];
  currentModule: any;
  pagingHeader: Pagination = { pageNum: PAGE_NUM_DEFAULT, pageSize: PAGE_SIZE_DEFAULT };
  pagingFooter: Pagination = { pageNum: PAGE_NUM_DEFAULT, pageSize: PAGE_SIZE_DEFAULT };
  totalHeader = PAGE_SIZE_DEFAULT;
  totalFooter = PAGE_SIZE_DEFAULT;
  previewHtmlCode = '';

  constructor(
    private store: Store,
    private templatesService: TemplatesService,
    private toastMessageService: ToastMessageService,
    private confirmationService: ConfirmationService,
    private translateService: TranslationService,
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['module']) {
      if (this.smartTableComponent) this.smartTableComponent.clearHeaderFooterSelected();
      this.pageNum = PAGE_NUM_DEFAULT;
      this.dispatchTemplateData(changes['module'].currentValue, this.emailType);
    }

    if (changes['emailType'] && changes['emailType'].currentValue) {
      if (changes['emailType'].currentValue === TEMPLATE_TYPES.HEADER) {
        this.getTemplateData(this.store.select(selectHeaderTemplates), TEMPLATE_TYPES.HEADER);
      }

      if (changes['emailType'].currentValue === TEMPLATE_TYPES.FOOTER) {
        this.getTemplateData(this.store.select(selectFooterTemplates), TEMPLATE_TYPES.FOOTER);
      }

      return;
    }
  }

  ngOnInit(): void {
    this.dispatchTemplateData(this.module, this.emailType);
    if (!this.emailType) {
      this.currentModule =
        this.templateModule === TEMPLATE_TYPES.MARKETING
          ? this.store.select(selectMarketingTemplates)
          : this.store.select(selectSystemTemplates);
      this.getTemplateData(this.currentModule, this.module);
    } else {
      this.getTemplateData(this.store.select(selectHeaderTemplates), TEMPLATE_TYPES.HEADER);
      this.getTemplateData(this.store.select(selectFooterTemplates), TEMPLATE_TYPES.FOOTER);
    }
  }

  updateTableData(data: any) {
    if (data) {
      this.tableData = data.data.slice(PAGE_NUM_DEFAULT, this.pageSize);
      this.totalElements = data.totalElement;
    }
  }

  onSearchTemplate(event: any): void {
    this.searchTextParam = event;
    this.dispatchTemplateData(this.module, this.emailType);
  }

  onChangePaging(event: any): void {
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.dispatchTemplateData(this.module, this.emailType);
  }

  getTemplateData(moduleStore: any, currentTemplateModule: string): void {
    moduleStore.subscribe((res: any) => {
      if (!Object.keys(res).length) {
        this.dispatchTemplateData(this.module, this.emailType);
        return;
      }

      if (this.emailType) {
        const currentTemplate = this.templateList.find(
          (el: any) => el.module === (currentTemplateModule || this.emailType)
        );
        if (currentTemplate) {
          this.templateList = this.templateList.map((el: any) => {
            el.templates = res;
            return el;
          });
          this.updateTableData(currentTemplate.templates);
        } else {
          this.templateList.push({ module: currentTemplateModule, templates: res });
          this.updateTableData(this.templateList[0]?.templates);
        }
      } else {
        this.templateList = res;
        const currentTemplate = this.templateList.find((el: any) => el.module === this.module);

        if (currentTemplate) {
          this.updateTableData(currentTemplate.templates);
        } else {
          this.updateTableData(this.templateList[0]?.templates);
        }
      }
    });
  }

  dispatchTemplateData(moduleType?: string, emailType?: string): void {
    this.store.dispatch(
      TemplatesActions.onLoadTemplates({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        emailType: emailType ? emailType : this.templateModule,
        moduleType: moduleType || '',
        searchText: this.searchTextParam || ''
      })
    );
  }

  loadMoreHeaderTemplate(event: { first: number; last: number }): void {
    if (
      event.first > this.pagingHeader.pageNum &&
      this.pagingHeader.pageNum * this.pagingHeader.pageSize <= this.totalHeader
    ) {
      const paging: Pagination = {
        pageNum: this.pagingHeader.pageNum + 1,
        pageSize: this.pagingHeader.pageSize
      };
      this.pagingHeader = paging;

      const params = {
        pageNum: this.pagingHeader.pageNum,
        pageSize: this.pagingHeader.pageSize,
        searchText: '',
        moduleType: this.module,
        emailType: TEMPLATE_TYPES.HEADER
      };
      this.templatesService.getTemplates(params).subscribe(rs => {
        if (rs.success) {
          this.totalHeader = rs.data.totalElement;
          this.store.dispatch(TemplatesApiActions.onLoadMoreHeaderTemplatesSuccess({ templates: rs.data }));
        }
      });
    }
  }

  loadMoreBottomTemplate(event: { first: number; last: number }): void {
    if (
      event.first > this.pagingFooter.pageNum &&
      this.pagingFooter.pageNum * this.pagingFooter.pageSize <= this.totalFooter
    ) {
      const paging: Pagination = {
        pageNum: this.pagingFooter.pageNum + 1,
        pageSize: this.pagingFooter.pageSize
      };
      this.pagingFooter = paging;

      const params = {
        pageNum: this.pagingFooter.pageNum,
        pageSize: this.pagingFooter.pageSize,
        searchText: '',
        moduleType: this.module,
        emailType: TEMPLATE_TYPES.FOOTER
      };
      this.templatesService.getTemplates(params).subscribe(rs => {
        if (rs.success) {
          this.totalFooter = rs.data.totalElement;
          this.store.dispatch(TemplatesApiActions.onLoadMoreFooterTemplatesSuccess({ templates: rs.data }));
        }
      });
    }
  }

  selectItemEvent(event: any): void {
    if (this.isSelectTemplateTable) {
      this.selectTemplateEvent.emit(event);
    } else {
      this.toggleFavoriteTemplate(event);
    }
  }

  toggleFavoriteTemplate(event: any): void {
    if (event) {
      this.templatesService.toggleFavoriteTemplate(event.id).subscribe({
        next: res => {
          if (res.success) {
            this.toastMessageService.addToastMessage(
              TOAST_MESSAGE_SEVERITY_LEVELS.success,
              event.is_favorite
                ? 'TEMPLATES.REMOVED_TEMPLATE_TO_FAVORITE_LIST_SUCCESSFULLY'
                : 'TEMPLATES.ADDED_TEMPLATE_TO_FAVORITE_LIST_SUCCESSFULLY',
              TOAST_MESSAGE_LIFETIME_DURATIONS.short
            );
            this.dispatchTemplateData(this.module, this.emailType);
          } else {
            this.toastMessageService.addToastMessage(
              TOAST_MESSAGE_SEVERITY_LEVELS.error,
              event.is_favorite
                ? 'TEMPLATES.FAILED_TO_REMOVE_TO_FAVORITE_LIST'
                : 'TEMPLATES.FAILED_TO_ADD_TO_FAVORITE_LIST',
              TOAST_MESSAGE_LIFETIME_DURATIONS.short
            );
          }
        },
        error: () => {
          this.toastMessageService.addToastMessage(
            TOAST_MESSAGE_SEVERITY_LEVELS.error,
            event.is_favorite
              ? 'TEMPLATES.FAILED_TO_REMOVE_TO_FAVORITE_LIST'
              : 'TEMPLATES.FAILED_TO_ADD_TO_FAVORITE_LIST',
            TOAST_MESSAGE_LIFETIME_DURATIONS.short
          );
        }
      });
    }
  }

  routeToEditTemplate(event: any): void {
    this.router.navigate([`/templates/custom-html/${event.id}`], {
      state: {
        config: JSON.stringify({ type: this.emailType ? this.emailType : this.templateModule })
      }
    });
  }

  onDeleteTemplate(event: any): void {
    if (event) {
      this.confirmationService.confirm({
        message: this.translateService.getTranslation('COMMON.PROCEED_ACTION_CONFIRMATION'),
        header: this.translateService.getTranslation('COMMON.DELETE_CONFIRMATION'),
        acceptLabel: this.translateService.getTranslation('ACTION_BUTTONS.DELETE'),
        rejectLabel: this.translateService.getTranslation('ACTION_BUTTONS.CANCEL'),
        acceptIcon: 'none',
        rejectIcon: 'none',
        acceptButtonStyleClass: 'p-button-danger delete-btn',
        rejectButtonStyleClass: 'normal-btn',
        accept: () => {
          this.templatesService.deleteTemplate(event.id).subscribe({
            next: res => {
              if (res.success) {
                this.toastMessageService.addToastMessage(
                  TOAST_MESSAGE_SEVERITY_LEVELS.success,
                  'TEMPLATES.DELETE_SUCCESSFULLY',
                  TOAST_MESSAGE_LIFETIME_DURATIONS.short
                );
                this.dispatchTemplateData(this.module, this.emailType);
              } else {
                this.toastMessageService.addToastMessage(
                  TOAST_MESSAGE_SEVERITY_LEVELS.error,
                  'TEMPLATES.FAILED_TO_DELETE',
                  TOAST_MESSAGE_LIFETIME_DURATIONS.short
                );
              }
            },
            error: () => {
              this.toastMessageService.addToastMessage(
                TOAST_MESSAGE_SEVERITY_LEVELS.error,
                'TEMPLATES.FAILED_TO_DELETE',
                TOAST_MESSAGE_LIFETIME_DURATIONS.short
              );
            }
          });
        },
        reject: () => {}
      });
    }
  }

  getHtmlFile(item: TemplateModel): void {
    if (item.email_header_id && item.email_footer_id) {
      this.templatesService.getHtmlFile(item.email_header_id).subscribe(headerHtml => {
        this.templatesService.getHtmlFile(item.file_id).subscribe(contentHtml => {
          this.templatesService.getHtmlFile(item.email_footer_id).subscribe(footerHtml => {
            this.previewHtmlCode = this.templatesService.getPreviewHtmlCode(headerHtml, contentHtml, footerHtml);
          });
        });
      });
    } else {
      this.templatesService.getHtmlFile(item.file_id).subscribe(contentHtml => {
        this.previewHtmlCode = contentHtml;
      });
    }
  }

  onPreviewTemplate(event: any) {
    this.getHtmlFile(event);
  }
}
