<div class="template-table">
  <em-smart-table
    [tableData]="tableData"
    [tableHeaders]="headerList"
    [tableName]="tableName"
    [showTableCheckbox]="showTableCheckbox"
    [showViewOption]="true"
    [roundedTable]="true"
    [showTableFilter]="false"
    [paging]="{pageNum, pageSize}"
    [totalElements]="totalElements"
    [checkBoxType]="checkBoxType.star"
    [searchPlaceholder]="'TEMPLATES.TEMPLATE_NAME'"
    [showOnlyGridLayout]="showOnlyGridLayout"
    [isShowConfigHeaderFooter]="isShowConfigHeaderFooter"
    [search]="searchText"
    [isLoading]="(isLoadingTemplate$ | async) || false"
    [isSelectTemplateTable]="isSelectTemplateTable"
    [configTemplate]="configTemplate"
    [(previewHtmlCode)]="previewHtmlCode"
    (callBackFunction)="dispatchTemplateData(module, emailType)"
    (pagingChange)="onChangePaging($event)"
    (searchItemEvent)="onSearchTemplate($event)"
    (selectItemEvent)="selectItemEvent($event)"
    (onSelectHeaderTemplate)="onSelectHeaderTemplate.emit($event)"
    (onSelectFooterTemplate)="onSelectFooterTemplate.emit($event)"
    (loadMoreHeaderTemplate)="loadMoreHeaderTemplate($event)"
    (loadMoreFooterTemplate)="loadMoreBottomTemplate($event)"
    (editItemEvent)="routeToEditTemplate($event)"
    (deleteItemEvent)="onDeleteTemplate($event)"
    (viewItemEvent)="onPreviewTemplate($event)"></em-smart-table>
  <p-confirmDialog styleClass="max-w-[400px]" />
</div>
