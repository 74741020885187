import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { BlockUIModule } from 'primeng/blockui';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';
import { TreeTableModule } from 'primeng/treetable';

import { DatetimeFormatterModule } from '@soctrip-common/datetime-formatter';
import { ConfirmationService } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';
import { GalleriaComponent } from './components/galleria/galleria.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { SmartTableComponent } from './components/smart-table/smart-table.component';
import { TemplateTableComponent } from './components/template-table/template-table.component';
import { ThumbnailHtmlComponent } from './components/thumbnail-html/thumbnail-html.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { ImageUrlFormatPipe } from './pipes/image-url-format.pipe';
import { MultiFieldsFilterPipe } from './pipes/multi-fields-filter.pipe';
import { RoundDecimalPipe } from './pipes/round-decimal.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ScaleContentDirective } from './pipes/scale-content.pipe';
import { SingleFieldFilterPipe } from './pipes/single-field-filter.pipe';
import { TimestampFormatPipe } from './pipes/timestamp-format.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';

const sharedComponents = [
  ToastMessageComponent,
  SmartTableComponent,
  PaginatorComponent,
  GalleriaComponent,
  TemplateTableComponent,
  LoadingComponent,
  ThumbnailHtmlComponent
];
const sharedPipes = [
  SingleFieldFilterPipe,
  MultiFieldsFilterPipe,
  DateTimeFormatPipe,
  TimestampFormatPipe,
  RoundDecimalPipe,
  ImageUrlFormatPipe,
  ScaleContentDirective,
  SafeHtmlPipe,
  TruncatePipe
];
const commonModules = [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule, DatetimeFormatterModule];
const primeNgModules = [
  SkeletonModule,
  InputSwitchModule,
  MenubarModule,
  ButtonModule,
  TableModule,
  MenuModule,
  DropdownModule,
  CardModule,
  InputTextModule,
  InputNumberModule,
  CheckboxModule,
  RadioButtonModule,
  DynamicDialogModule,
  BreadcrumbModule,
  AccordionModule,
  TimelineModule,
  CalendarModule,
  InputTextareaModule,
  ChipModule,
  ChipsModule,
  AvatarModule,
  RatingModule,
  TagModule,
  DividerModule,
  MultiSelectModule,
  PaginatorModule,
  ProgressBarModule,
  SplitButtonModule,
  ToastModule,
  DialogModule,
  TabViewModule,
  TabMenuModule,
  ToolbarModule,
  GalleriaModule,
  SliderModule,
  PasswordModule,
  ListboxModule,
  AutoCompleteModule,
  ConfirmDialogModule,
  SidebarModule,
  TreeTableModule,
  TreeModule,
  ProgressSpinnerModule,
  DividerModule,
  SelectButtonModule,
  ImageModule,
  TreeSelectModule,
  BlockUIModule,
  TooltipModule
];

@NgModule({
  declarations: [
    sharedComponents,
    sharedPipes,
    DateTimeFormatPipe,
    TimestampFormatPipe,
    RoundDecimalPipe,
    ImageUrlFormatPipe,
    CapitalizePipe
  ],
  imports: [commonModules, primeNgModules],
  exports: [commonModules, primeNgModules, sharedComponents, sharedPipes],
  providers: [ConfirmationService, DatePipe]
})
export class SharedModule {}
