import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TemplatesService } from '@app/core/service/templates.service';
import { ToastMessageService } from '@app/core/service/toast-message.service';
import { TranslationService } from '@app/core/service/translation.service';
import { ApplyHeaderFooterBody } from '@app/lib/api/templates/api.templates.model';
import {
  selectFooterTemplates,
  selectHeaderTemplates
} from '@app/modules/main/templates/store/email-template.selectors';
import {
  CHECKBOX_TYPE,
  DefaultPaginator,
  ITEM_NAMES,
  PAGE_NUM_DEFAULT,
  PAGE_SIZE_DEFAULT,
  TOAST_MESSAGE_LIFETIME_DURATIONS,
  TOAST_MESSAGE_SEVERITY_LEVELS,
  TOTAL_ELEMENT_DEFAULT,
  VIEW_OPTION
} from '@app/shared/constant';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { Pagination } from '../paginator/paginator.component';
import { COLOR_MAPPING } from './color-mapping';
import { COLOR_MODULE_MAPPING } from './color-module-mapping';
import { TABLE_HEADER_LIST } from './key-mapping';

@Component({
  selector: 'em-smart-table',
  templateUrl: './smart-table.component.html',
  styleUrls: ['./smart-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SmartTableComponent implements OnInit {
  @Input() tableData: any;
  @Input() tableHeaders: string[] = [];
  @Input() tableName: string;
  @Input() showTableCheckbox = false;
  @Input() showHeaderCheckbox = false;
  @Input() showTableSearch = true;
  @Input() showTableFilter = true;
  @Input() showEditAction = true;
  @Input() showPageSizeSelection = true;
  @Input() searchPlaceholder: string;
  @Input() statusOptions: any[];
  @Input() checkBoxType = CHECKBOX_TYPE.normal;
  @Input() roundedTable = false;
  @Input() showViewOption = false;
  @Input() paging: Pagination = { pageNum: PAGE_NUM_DEFAULT, pageSize: PAGE_SIZE_DEFAULT };
  @Input() totalElements = TOTAL_ELEMENT_DEFAULT;
  @Input() showOnlyGridLayout = false;
  @Input() useDefaultPaginator = false;
  @Input() isShowConfigHeaderFooter = false;
  @Input() search = new FormControl('');
  @Input() isLoading = false;
  @Input() isSelectTemplateTable = false;
  @Input() configTemplate = false;
  @Input() previewHtmlCode = '';
  @Input() isPreviewOnly = false;
  @Output() previewHtmlCodeChange = new EventEmitter();
  @Output() pagingChange = new EventEmitter();
  @Output() pageNumChangeEvent = new EventEmitter();
  @Output() pageSizeChangeEvent = new EventEmitter();
  @Output() deleteItemEvent = new EventEmitter();
  @Output() editItemEvent = new EventEmitter();
  @Output() duplicateItemEvent = new EventEmitter();
  @Output() selectItemEvent = new EventEmitter();
  @Output() searchItemEvent = new EventEmitter();
  @Output() actionInputSwitchEvent = new EventEmitter();
  @Output() filterStatusEvent = new EventEmitter();
  @Output() viewItemEvent = new EventEmitter();
  @Output() onSelectHeaderTemplate = new EventEmitter();
  @Output() onSelectFooterTemplate = new EventEmitter();
  @Output() loadMoreHeaderTemplate = new EventEmitter();
  @Output() loadMoreFooterTemplate = new EventEmitter();
  @Output() refreshUserCountEvent = new EventEmitter();
  @Output() callBackFunction = new EventEmitter();
  @ViewChild('statusMultiSelect') statusMultiSelectRef: MultiSelect;

  private destroy$ = new Subject<void>();
  baseUrl: string = environment.API_URL;
  itemNames = ITEM_NAMES;
  isOpenActionsMenu = false;
  isLoadingApplyTemplate = false;
  tableActionsMenu: MenuItem[] | any;
  translateResult: any;
  selectedItem: any;
  selectedStatus: any[] = [];
  apiUrl = environment.API_URL;
  headerTemplates: any[] = [];
  selectedHeaderTemplate: any;
  footerTemplates: any[] = [];
  selectedFooterTemplate: any;
  previewImageUrl: string;

  constructor(
    private translateService: TranslationService,
    private store: Store,
    private templatesService: TemplatesService,
    private toastMessageService: ToastMessageService,
    private confirmationService: ConfirmationService,
    private sanitizer: DomSanitizer
  ) {}

  selectedViewOption = VIEW_OPTION.list;
  viewOption = VIEW_OPTION;
  checkBoxTypeConstant = CHECKBOX_TYPE;
  selectedRowsPerPage: number[] = DefaultPaginator.rowsPerPageOptions;
  isLoadMoreHeader = false;
  isLoadMoreFooter = false;
  templateSelectedArray: string[] = [];

  ngOnInit(): void {
    this.search.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(textSearch => {
      this.searchItemEvent.emit(textSearch);
    });
    this.store.select(selectHeaderTemplates).subscribe(res => {
      if (res.data) {
        this.headerTemplates = res.data;
        this.isLoadMoreHeader = !(res.data.length >= res.totalElement);
      }
    });
    this.store.select(selectFooterTemplates).subscribe(res => {
      if (res.data) {
        this.footerTemplates = res.data;
        this.isLoadMoreFooter = !(res.data.length >= res.totalElement);
      }
    });
  }

  get tableKeys(): string[] | any {
    return this.tableHeaders.map(item => TABLE_HEADER_LIST.get(item) ?? item.toLocaleLowerCase()) ?? [];
  }

  isNumberValue(value: string, isHeader = true): boolean {
    let numberArr = ['NEW_USERS_IN_30_DAYS', 'USER_COUNTS', 'OPEN_RATE', 'CLICK_RATE', 'SENT_MAILS'];
    if (isHeader) {
      numberArr = numberArr.map(item => item.toUpperCase());
      return numberArr.includes(value);
    }
    numberArr = numberArr.map(item => TABLE_HEADER_LIST.get(item) ?? item.toLocaleLowerCase());
    return numberArr.includes(value);
  }

  setUpMenuItems(): void {
    this.translateResult = this.translateService.getTranslation('ACTION_BUTTONS');
    this.tableActionsMenu = [
      {
        label: `<span class=" text-sm font-medium flex align-items-start">${this.translateResult.EDIT}</span>`,
        escape: false,
        icon: 'sctr-icon-edit-03',
        command: () => this.editItemEvent.emit(this.selectedItem)
      },
      {
        label: `<span class="text-system-error-600 font-medium text-sm flex align-items-start">${this.translateResult.DELETE}</span>`,
        escape: false,
        icon: 'sctr-icon-trash-01',
        iconClass: 'delete-icon',
        command: () => this.deleteItemEvent.emit(this.selectedItem)
      }
    ];
    if ([this.itemNames.audience, this.itemNames.campaign, this.itemNames.templates].includes(this.tableName)) {
      this.tableActionsMenu.unshift({
        label: `<span class=" text-sm font-medium flex align-items-start">${this.translateResult.VIEW}</span>`,
        escape: false,
        icon: 'sctr-icon-eye',
        command: () => this.viewItemEvent.emit(this.selectedItem)
      });
    }
  }

  onClickMenuButton = (selectedItem: any) => {
    this.selectedItem = selectedItem;
    this.setUpMenuItems();
    this.isOpenActionsMenu = true;
  };

  onSelectTemplate(selectedItem: any): void {
    if (this.isSelectTemplateTable) {
      this.selectedItem = selectedItem;
      this.selectItemEvent.emit(selectedItem);
    }

    if (this.isPreviewOnly) {
      this.viewItemEvent.emit(selectedItem);
    }
  }

  upperCaseFormat(value: string): string {
    return value.toLocaleUpperCase();
  }

  updateStatusColor(status: string): string {
    return COLOR_MAPPING.get(status) || '';
  }

  updateModuleColor(module: string): string {
    return COLOR_MODULE_MAPPING.get(module) || '';
  }

  onDeleteItem(item: any): void {
    this.deleteItemEvent.emit(item);
  }

  onChangeInputSwitch(item: any, checked: any): void {
    this.actionInputSwitchEvent.emit({ data: item, checked });
  }

  onSelectionChange(selectedItems: any): void {
    this.selectItemEvent.emit(selectedItems);
  }

  selectedStatusList(): string {
    return this.selectedStatus
      .map(item => this.translateService.getTranslation(`COMMON.${this.upperCaseFormat(item.name)}`))
      .join(', ');
  }

  setViewOption(option: string): void {
    this.selectedViewOption = option;
  }

  onChangePaging(event: Pagination): void {
    this.pagingChange.emit(event);
  }

  loadMoreHeader(event: any): void {
    if (this.isLoadMoreHeader) {
      this.loadMoreHeaderTemplate.emit(event);
    }
  }

  loadMoreFooter(event: any): void {
    if (this.isLoadMoreFooter) {
      this.loadMoreFooterTemplate.emit(event);
    }
  }

  getThumbnailUrl(url: string): string {
    return `${this.apiUrl}/storage/files/thumbnail/${url}.webp`;
  }

  getCampaignStatus(campaign: any): string {
    if (campaign.is_draft) {
      return 'DRAFT';
    } else {
      return campaign.enabled ? 'ACTIVE' : 'INACTIVE';
    }
  }

  onFilterStatus(): void {
    this.filterStatusEvent.emit(this.selectedStatus);
    this.statusMultiSelectRef.hide();
  }

  handleClickApplyTemplate(): void {
    const body: ApplyHeaderFooterBody = {
      templateIds: this.templateSelectedArray,
      email_header_id: this.selectedHeaderTemplate.file_id,
      email_footer_id: this.selectedFooterTemplate.file_id
    };

    this.confirmationService.confirm({
      message: this.translateService.getTranslation('TEMPLATES.CONFIRM_APPLY_HEADER_FOOTER'),
      header: this.translateService.getTranslation('COMMON.CONFIRM_CHANGE'),
      acceptLabel: this.translateService.getTranslation('ACTION_BUTTONS.CONFIRM'),
      rejectLabel: this.translateService.getTranslation('ACTION_BUTTONS.CANCEL'),
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptButtonStyleClass: 'p-button confirm-btn',
      rejectButtonStyleClass: 'normal-btn',
      accept: () => {
        this.isLoadingApplyTemplate = true;
        this.templatesService.applyHeaderFooter(body).subscribe({
          next: res => {
            if (res.success) {
              this.toastMessageService.addToastMessage(
                TOAST_MESSAGE_SEVERITY_LEVELS.success,
                'TEMPLATES.APPLY_TEMPLATES_SUCCESSFULLY',
                TOAST_MESSAGE_LIFETIME_DURATIONS.short
              );
              this.templateSelectedArray = [];
              this.clearHeaderFooterSelected();
              this.callBackFunction.emit();
            } else {
              this.toastMessageService.addToastMessage(
                TOAST_MESSAGE_SEVERITY_LEVELS.error,
                'TEMPLATES.FAILED_TO_APPLY',
                TOAST_MESSAGE_LIFETIME_DURATIONS.short
              );
            }
            this.isLoadingApplyTemplate = false;
          },
          error: () => {
            this.toastMessageService.addToastMessage(
              TOAST_MESSAGE_SEVERITY_LEVELS.error,
              'TEMPLATES.FAILED_TO_APPLY',
              TOAST_MESSAGE_LIFETIME_DURATIONS.short
            );
            this.isLoadingApplyTemplate = false;
          }
        });
      },
      reject: () => {}
    });
  }

  clearHeaderFooterSelected(): void {
    this.selectedHeaderTemplate = null;
    this.selectedFooterTemplate = null;
  }

  handleSelectedCheckBox(id: string): void {
    const isChecked = this.templateSelectedArray.some(tempId => tempId === id);
    if (!isChecked) this.templateSelectedArray = [...this.templateSelectedArray, id];
    else this.templateSelectedArray = this.templateSelectedArray.filter(tempId => tempId !== id);
  }
}
