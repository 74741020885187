import { HttpClient } from '@angular/common/http';
import { CommonService } from '@app/core/service/common.service';
import { environment } from '@env/environment';
import { Observable, catchError, map, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import {
  AddTemplateBody,
  AddTemplateResponse,
  ApplyHeaderFooterBody,
  GetSystemTemplateParams,
  GetSystemTemplateResponse,
  GetTemplateParams,
  GetTemplateResponse,
  GetTemplateResponsePaging,
  UploadFileResponse
} from './api.templates.model';

export class TemplatesApi {
  private apiUrl = environment.API_URL;

  TEMPLATE = '/template';
  EMAIL_MARKETING = '/email-marketing';

  constructor(public readonly http: HttpClient, public config: ApiClientConfig, private commonService: CommonService) {}

  uploadFile(file: File, description: string): Observable<UploadFileResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http
      .post<UploadFileResponse>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.TEMPLATE}/files/upload`, formData, {
        params: { description }
      })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getFile(fileId: string): Observable<string> {
    return this.http
      .get<string>(`${this.apiUrl}/storage/files/download/${fileId}`, { responseType: 'text' as 'json' })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  addTemplate(body: AddTemplateBody, moduleType: string): Observable<AddTemplateResponse> {
    return this.http
      .post<AddTemplateResponse>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.TEMPLATE}/${moduleType}`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getTemplates(params: GetTemplateParams): Observable<GetTemplateResponsePaging> {
    return this.http
      .get<GetTemplateResponsePaging>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.TEMPLATE}`, {
        params: { ...params, sortType: 'DESC', sortField: 'UPDATED_AT' }
      })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getTemplateById(templateId: string): Observable<GetTemplateResponse> {
    return this.http
      .get<GetTemplateResponse>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.TEMPLATE}/${templateId}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getAvailableParameters(module: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.TEMPLATE}/${module}/available-parameters`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  toggleFavoriteTemplate(templateId: string): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.TEMPLATE}/favorite/${templateId}`, {}).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  deleteTemplate(templateId: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.TEMPLATE}/${templateId}`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  applyHeaderFooter(body: ApplyHeaderFooterBody): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.TEMPLATE}/apply`, body).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  editTemplate(body: AddTemplateBody, moduleType: string): Observable<AddTemplateResponse> {
    return this.http
      .put<AddTemplateResponse>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.TEMPLATE}/${moduleType}`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getSystemEmailTemplate(params: GetSystemTemplateParams): Observable<GetSystemTemplateResponse> {
    return this.http
      .get<GetSystemTemplateResponse>(`${this.apiUrl}${this.EMAIL_MARKETING}${this.TEMPLATE}/system-emails`, {
        params: { ...params }
      })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
